<template>
  <base-card dark>
    <v-img
      :src="require('@/assets/botellas/esr_2021_min.jpg')"
      width="100%"
    >
      <v-row
        class="fill-height pa-3"
      >
        <v-col
          cols="12"
          md="6"
          offset-md="6"
        >
          <h1 class="display-2 font-weight-light">
            {{ content.bannerTitle }}
          </h1>

          <div class="subheading text-uppercase pl-2 mb-4">
            {{ content.bannerSubtitle }}
          </div>

        </v-col>
      </v-row>
    </v-img>
  </base-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
  }
</script>
